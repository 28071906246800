import menuItemList from './menu-items';
import { AnimatePresence } from 'framer-motion';
import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import AuthGuard from './component/Auth/AuthGuard';
import GuestGuard from './component/Auth/GuestGuard';
import Loader from './component/Loader/Loader';
import config from './config';
import useAuth from './hooks/useAuth';
import MainLayout from './layout/MainLayout';
import MinimalLayout from './layout/MinimalLayout';
import NavMotion from './layout/NavMotion';

const NotFound = lazy(() => import('./views/Pages/Error/Error1')); //404
const AuthLogin = lazy(() => import('./views/Login'));
const Dashboard = lazy(() => import('./views/Dashboard'));
const UserManagement = lazy(() => import('./views/Settings/UserManagement'));
const MyAccount = lazy(() => import('./views/Settings/MyAccount'));
const Location = lazy(() => import('./views/Location'));
const Transaction = lazy(() => import('./views/Transaction'));
const Member = lazy(() => import('./views/Member'));
const Machine = lazy(() => import('./views/Machine'));
const Server = lazy(() => import('./views/Server'));
const History = lazy(() => import('./views/History'));
const IpManagement = lazy(() => import('./views/IpManagement'));
const LogCenter = lazy(() => import('./views/LogCenter'));
const Duplicate = lazy(() => import('./views/Duplicate'));
const LateRedemption = lazy(() => import('./views/LateRedemtion'))

const Gift = lazy(() => import('./views/Gift'));

const MapView = lazy(() => import('./views/AvailableMapView'));
const ContractorManagement = lazy(() => import('./views/Settings/ContractorManagement'));

const Routes = () => {
    const homeUrl = config.home_url;
    const { menuItem } = useAuth();
    const location = useLocation();
    const paths = useRef([]);

    const genUrlList = (list) => {
        for (let i in list) {
            const item = list[i];
            if (menuItem[item.id] && item.url) paths.current.push(item.url);
            if (item.children) genUrlList(item.children);
        }
    };

    useEffect(() => {
        if (menuItem) genUrlList(menuItemList);
    }, [menuItem]);

    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Redirect exact from="/" to={homeUrl} />
                    <Route path={['/login']}>
                        <MinimalLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <GuestGuard>
                                        <Route path="/login" component={AuthLogin} />
                                    </GuestGuard>
                                </NavMotion>
                            </Switch>
                        </MinimalLayout>
                    </Route>
                    <Route path={paths.current}>
                        <MainLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <AuthGuard>
                                        {/* <Route path="/dashboard" component={Dashboard} /> */}
                                        <Route path="/location" component={Location} />
                                        <Route path="/transaction" component={Transaction} />
                                        <Route path="/member" component={Member} />
                                        <Route path="/ipManagement" component={IpManagement} />
                                        <Route path="/logCenter" component={LogCenter} />
                                        <Route path="/duplicate" component={Duplicate} />
                                        <Route path="/lateRedemption" component={LateRedemption} />
                                        <Route path="/gift" component={Gift} />
                                        <Route path="/machine" component={Machine} />
                                        <Route path="/mapview" component={MapView} />
                                        <Route path="/server" component={Server} />
                                        <Route path="/machineLogHistory" component={History} />

                                        <Route path="/setting/myaccount" component={MyAccount} />
                                        <Route path="/setting/alluser" component={UserManagement} />
                                        <Route path="/setting/allcontractor" component={ContractorManagement} />
                                        <div style={{ display: "flex", flexDirection: "row-reverse", fontSize: "9px", position: "relative" }}>V.3.0.1</div>
                                    </AuthGuard>
                                </NavMotion>
                            </Switch>
                        </MainLayout>
                    </Route>
                    <Route path={'/'}>
                        <MainLayout>
                            <Switch location={location} key={location.pathname}>
                                <NavMotion>
                                    <AuthGuard>
                                        <Route exact={true} component={NotFound} />
                                    </AuthGuard>
                                </NavMotion>
                            </Switch>
                        </MainLayout>
                    </Route>
                </Switch>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
