const sc = {
    Lang: 'sc',
    Edit: '编辑',
    Create: '新增',
    Logout: '登出',
    Global: {
        Home: '主页',
        NO_RECORD: '没有记录',
        Cancel: '取消',
        Clear: '清除',
        Submit: '递交',
        Reset: '重置',
        Edit: '编辑',
        Create: '新增',
        DeleteReminder: '确认删除记录？',
        User: '用戶',
        Upload: '上传',
    },
    Login: {
        WebPortal: '入口网站',
        Username: '用户名称',
        Password: '密码',
        Login: '登入',
    },
    Muidatatable: {
        body: {
            noMatch: '对不起，没有找到匹配的记录',
            toolTip: '排序',
        },
        pagination: {
            next: '下一页',
            previous: '上一页',
            rowsPerPage: '每页行数:',
            displayRows: '共',
        },
        toolbar: {
            search: '搜索',
            downloadCsv: '下载CSV',
            filterTable: '过滤表格',
        },
        filter: {
            all: '全部',
            title: '过滤器',
            reset: '重置',
        },
    },
    Alert: {
        Title: '警告!',
        Content1: '请定期更改您的密码。',
        Content2: '不要与别人分享您的密码',
    },
    Dashboard: {
        Title: '仪表板',
    },
    Map: {
        Title: '地圖',
        MachineID: '机器ID',
        Description: '描述',
        Status: '状态',
        ServerStatus: '伺服器状态',
        Alerts: '警报或事件',
        Index: '警报ID',
        CapacityStatus: '容量状态',
        GeneralStatus: '一般状态',
        SpecialStatus: '自订状态',
        SelfDefinedCode: '自订',
        Timestamp: '时间',
        createdAt: '创建时间',
        Bin_Type: '回收桶类型',
        Level: '等级',
        Code: '代码'
    },
    Server: {
        Index: '编号.',
        Title: '伺服器',
        Server: '伺服器',
        Last_heartbeat: '最近记录',
        Status: '状态',
    },
    History: {
        Index: '编号.',
        Title: '历史记录',
        MachineID: '机器ID',
        Description: '描述',
        Last_heartbeat: '最近记录',
        Status: '状态',
    },
    Location: {
        Title: '地点',
        Create: '新增地点',
        Edit: '编辑地点',
        Location_ID: '地点编号',
        CP_ID: 'CPID',
        Latitude: '纬度',
        Longitude: '经度',
        District: '地区',
        Location_Type: '地点类型',
        Address: '地址 (英文)',
        Address_zh_hant: '地址 (繁体)',
        Remarks: '备注',
        LastUpdate: '最后更新时间',
    },
    Transaction: {
        Title: '交易',
        Transaction_ID: '交易编号',
        Transaction_Time: '交易时间',
        Transaction_Type: '交易类型',
        Location_ID: '地点编号',
        Machine: '机器编号',
        Staff: '员工编号',
        Member_ID: '会员编号',
        Green_Score: '绿绿赏',
        Type: '类型',
        Weight: '重量',
        Source: '来源',
        Status: '状态',
        Import_Transaction_CSV: '导入交易CSV',
        Drop_file_content_1: '将文件放在这里 ...',
        Drop_file_content_2: '把文件放在这里，或点击选择文件',
        Export_Transaction_CSV: '导出交易CSV',
        StartDate: '开始日期',
        EndDate: '结束日期',
    },
    Member: {
        Title: '会员',
        Download_Member: '下载会员CSV',
    },
    Machine: {
        Title: '机器',
        Machine_ID: '机器编号',
        Serial_Number: '序列号',
        Contractor: '供应商',
        Machine_Type: '机器类型',
        Location_ID: '地点编号',
        Bin_Weight: '回收桶重量',
        Bin_Level: '回收桶等级',
        Remarks: '备注',
        Status: '状态',
        Start_Time: '开启时间',
        Close_Time: '关闭时间',
        Created_at: '新增日期',
        Updated_at: '最后更新',
        message: 'test message',
        binWeightOrLevelError: '回收桶重量及回收桶等级只能输入数字',
    },
    Gift: {
        Title: '礼品',
        Name: '礼品名称',

        Name_tc: '礼品名称 (繁)',
        Name_sc: '礼品名称 (简)',
        Name_en: '礼品名称 (英)',
        greenScore: '绿绿赏',
        Edit_gift: '编辑礼品',
        Create_gift: '新增礼品',
        Gift_ID: '礼品编号',
        Description: '礼品描述',
        Remarks: '备注',
        Created_at: '新增日期',
        Updated_at: '最后更新',
    },
    IpManagement: {
        Title: 'IP 管理',
        IP_Address: 'IP 位址',
        Machine_ID: '机器编号',
        Active_Time: '启用时间',
        Activate: '启用',
        Active_Date_Picker: '选择启用日期',
        Active_Time_Picker: '选择启用时间',
        Not_Active: '未生效',
    },
    LogCenter: {
        Title: '日志中心',
        MemberID: '会员编号',
        MachineID: '机器ID',
        Type: '类型',
        TransactionTime: '交易时间',
        DuplicateData: '重复数据',
        MachineIDnLocationID: '机器ID & 地点ID',
        Whitelist: '白名单',
        IP: 'IP',
        Path: '路径',
        LocationID: '地点编号',
        Invaild: '无效',
        Weight: '重量',
        GreenScore: '绿绿赏',
    },
    Duplicate: {
        Title: '重复数据',
        MemberID: '会员编号',
        MachineID: '机器编号',
        TransactionID: '交易编号',
        TransactionType: '交易类型',
        Weight: '重量',
        GreenScore: '绿绿赏',
        TransactionDate: '交易日期',
        TransactionTime: '交易时间',
        Type: '类型',
        AdjustmentData: '调整数据',
        LocationID: '地点编号',
        Remarks: '备注',
        Updated_at: '最后更新',
    },
    LateRedemption: {
        Title: '逾期兑换',
        MemberID: '会员编号',
        MachineID: '机器ID',
        TransactionID: '交易ID',
        TransactionType: '交易类型',
        Weight: '重量',
        GreenScore: '绿绿赏',
        TransactionTime: '交易时间',
        Type: '类型',
        Updated_at: '最后更新',
        LocationID: '地点编号',
    },
    Setting: {
        Title: '设置',
        My_Account: {
            Title: '我的账户',
            Account_Details: '账户详情',
            Change_Password: '更改密码',
            Staff_ID: '员工编号',
            Username: '用户名称',
            Role: '身份',
            Current_Password: '旧密码',
            New_Password: '新密码',
            Re_enter: '重新输入新密码',
            Change_Password_button: '更改密码',
            OLD_PASSWORD_NOT_MATCH: '当前密码不匹配',
            NEW_PASSWORD_SAME_AS_OLD_PASSWORD: '新密码不能与旧密码相同',
            USERNAME_DUPLICATED: '用户名称已被使用',
            PasswordChanged: '密码已成功更改！',
        },
        User_Management: {
            Title: '用户管理',
            Staff: '员工',
            Staff_ID: '员工编号',
            Username: '帐户名称',
            Role: '身份',
            Created_at: '创建日期',
            Last_Modification: '最后修改',
            Reset_Password_button: '重設密码',
            New_Password: '新密码',
            Re_enter: '重新输入新密码',
            Change_Password_button: '更改密码',
        },
        Contractor_Management: {
            Title: '供应商管理',
            Contractor_ID: '供应商ID',
        },
    },
    Validation: {
        Required: '必填',
        InvalidEmailFormat: '必须是有效的电子邮件',
        RequireEmail: '输入邮箱地址',
        RequirePassword: '输入密码',
        RequireConfirmPassword: '确认您的密码',
        RequireOldPassword: '输入当前密码',
        RequireNewPassword: '输入新密码',
        RequireRole: '选择一个角色',
        RequiredFieldInvalid: '必填字段无效,请检查',

        PasswordTooShort: '密码使用 8 个或更多字符',
        PasswordRequireUppercase: '密码必须至少包含一个大写字符',
        PasswordRequireLowercase: '密码必须至少包含一个小写字符',
        PasswordRequireDigit: '密码必须至少包含一位数字',
        PasswordRequireSymbol: '密码必须至少包含一个符号',
        ConfirmPasswordNotMatch: '密码不匹配',
        RequireDigit: '必须是数字',
    },
    Snackbar: {
        InvalidLogin: '用户名或密码无效',
        SessionExpired: '您的会话已过期，请重新登录',
        NoDateRangeRecord: '未找到记录，请选择其他日期范围并重试',
    },
};

export default sc;
