const tc = {
    Lang: 'tc',
    Edit: '編輯',
    Create: '新增',
    Logout: '登出',
    Global: {
        Home: '主頁',
        NO_RECORD: '沒有記錄',
        Cancel: '取消',
        Clear: '清除',
        Submit: '遞交',
        Reset: '重置',
        Edit: '編輯',
        Create: '新增',
        DeleteReminder: '確認刪除記錄？',
        User: '用戶',
        Upload: '上傳',
    },
    Login: {
        WebPortal: '入口網站',
        Username: '用戶名稱',
        Password: '密碼',
        Login: '登入',
    },
    Muidatatable: {
        body: {
            noMatch: '對不起，沒有找到匹配的記錄',
            toolTip: '排序',
        },
        pagination: {
            next: '下一頁',
            previous: '上一頁',
            rowsPerPage: '每頁行數:',
            displayRows: '共',
        },
        toolbar: {
            search: '搜索',
            downloadCsv: '下載CSV',
            filterTable: '過濾表格',
        },
        filter: {
            all: '全部',
            title: '過濾器',
            reset: '重置',
        },
    },
    Alert: {
        Title: '警告',
        Content1: '請定期更改您的密碼',
        Content2: '不要與別人分享您的密碼',
    },
    Map: {
        Title: '地圖',
        MachineID: '機器ID',
        Description: '描述',
        Status: '狀態',
        ServerStatus: '伺服器狀態',
        Alerts: '警報或事件',
        Index: '警報ID',
        CapacityStatus: '容量狀態',
        GeneralStatus: '一般狀態',
        SpecialStatus: '自訂狀態',
        SelfDefinedCode: '自訂',
        Timestamp: '時間',
        createdAt: '創建時間',
        Bin_Type: '回收桶類型',
        Level: '等級',
        Code: '代碼'
    },
    Server: {
        Title: '伺服器',
        Index: '編號.',
        Server: '伺服器',
        Last_heartbeat: '最近記錄',
        Status: '狀態',
    },
    History: {
        Index: '編號.',
        Title: '歷史記錄',
        MachineID: '機器ID',
        Description: '描述',
        Last_heartbeat: '最近記錄',
        Status: '狀態',
    },
    Dashboard: {
        Title: '儀表板',
    },
    Location: {
        Title: '地點',
        Create: '新增地點',
        Edit: '編輯地點',
        Location_ID: '地點編號',
        CP_ID: 'CPID',
        Latitude: '緯度',
        Longitude: '經度',
        District: '地區',
        Location_Type: '地點類型',
        Address: '地址 (英文)',
        Address_zh_hant: '地址 (繁體)',
        Remarks: '備注',
        LastUpdate: '最後更新時間',
    },
    Transaction: {
        Title: '交易',
        Transaction_ID: '交易編號',
        Transaction_Time: '交易時間',
        Transaction_Type: '交易類型',
        Location_ID: '地點編號',
        Machine: '機器編號',
        Staff: '員工編號',
        Member_ID: '會員編號',
        Green_Score: '綠綠賞',
        Type: '類型',
        Weight: '重量',
        Source: '來源',
        Status: '狀態',
        Import_Transaction_CSV: '導入交易CSV',
        Drop_file_content_1: '將文件放在這里 ...',
        Drop_file_content_2: '把文件放在這里，或點擊選擇文件',
        Export_Transaction_CSV: '導出交易CSV',
        StartDate: '開始日期',
        EndDate: '結束日期',
    },
    Member: {
        Title: '會員',
        Download_Member: '下載會員CSV',
    },
    Machine: {
        Title: '機器',
        Machine_ID: '機器編號',
        Serial_Number: '序列號',
        Machine_Type: '機器類型',
        Contractor: '供應商',
        Location_ID: '地點編號',
        Bin_Weight: '回收桶重量',
        Bin_Level: '回收桶等級',
        Remarks: '備註',
        Status: '狀態',
        Start_Time: '開啟時間',
        Close_Time: '關閉時間',
        Created_at: '新增日期',
        Updated_at: '最後更新',
        binWeightOrLevelError: '回收桶重量及回收桶等級只能輸入數字',
    },
    Gift: {
        Title: '禮品',
        Name: '禮品名稱',
        Name_tc: '禮品名稱 (繁)',
        Name_sc: '禮品名稱 (簡)',
        Name_en: '禮品名稱 (英)',
        greenScore: '綠綠賞',
        Edit_gift: '編輯禮品',
        Create_gift: '新增禮品',
        Gift_ID: '禮品編號',
        Description: '禮品描述',
        Remarks: '備註',
        Created_at: '新增日期',
        Updated_at: '最後更新',
    },
    IpManagement: {
        Title: 'IP 管理',
        IP_Address: 'IP 位址',
        Machine_ID: '機器編號',
        Active_Time: '啟用時間',
        Activate: '啟用',
        Active_Date_Picker: '選擇啟用日期',
        Active_Time_Picker: '選擇啟用時間',
        Not_Active: '未生效',
    },
    LogCenter: {
        Title: '日誌中心',
        MemberID: '會員編號',
        MachineID: '機器ID',
        Type: '類型',
        TransactionTime: '交易時間',
        DuplicateData: '重複數據',
        MachineIDnLocationID: '機器ID & 地點ID',
        Whitelist: '白名單',
        IP: 'IP',
        Path: '路徑',
        LocationID: '地點編號',
        Invaild: '無效',
        Weight: '重量',
        GreenScore: '綠綠賞',
    },
    Duplicate: {
        Title: '重複數據',
        MemberID: '會員編號',
        MachineID: '機器編號',
        TransactionID: '交易編號',
        TransactionType: '交易類型',
        Weight: '重量',
        GreenScore: '綠綠賞',
        TransactionDate: '交易日期',
        TransactionTime: '交易時間',
        Type: '類型',
        AdjustmentData: '調整數據',
        LocationID: '地點編號',
        Remarks: '備注',
        Updated_at: '最後更新',
    },
    LateRedemption: {
        Title: '逾期兌換',
        MemberID: '會員編號',
        MachineID: '機器ID',
        TransactionID: '交易ID',
        TransactionType: '交易類型',
        Weight: '重量',
        GreenScore: '綠綠賞',
        TransactionTime: '交易時間',
        Type: '類型',
        Updated_at: '最後更新',
        LocationID: '地點編號',
    },
    Setting: {
        Title: '設定',
        My_Account: {
            Title: '我的帳戶',
            Account_Details: '賬戶詳情',
            Change_Password: '更改密碼',
            Staff_ID: '員工編號',
            Username: '用戶名',
            Role: '身份',
            Current_Password: '舊密碼',
            New_Password: '新密碼',
            Re_enter: '重新輸入新密碼',
            Change_Password_button: '更改密碼',
            OLD_PASSWORD_NOT_MATCH: '當前密碼不匹配',
            NEW_PASSWORD_SAME_AS_OLD_PASSWORD: '新密碼不能與舊密碼相同',
            USERNAME_DUPLICATED: '用戶名稱已被使用',
            PasswordChanged: '密碼已成功更改！',
        },
        User_Management: {
            Title: '用戶管理',
            Staff: '員工',
            Staff_ID: '員工編號',
            Username: '帳戶名稱',
            Role: '身份',
            Created_at: '創建日期',
            Last_Modification: '最後修改',
            Reset_Password_button: '重設密碼',
            New_Password: '新密碼',
            Re_enter: '重新輸入新密碼',
            Change_Password_button: '更改密碼',
        },
        Contractor_Management: {
            Title: '供應商管理',
            Contractor_ID: '供應商ID',
        },
    },
    Validation: {
        Required: '必填',
        InvalidEmailFormat: '必須是有效的電子郵件',
        RequireEmail: '輸入郵箱地址',
        RequirePassword: '輸入密碼',
        RequireConfirmPassword: '確認您的密碼',
        RequireOldPassword: '輸入當前密碼',
        RequireNewPassword: '輸入新密碼',
        RequireRole: '選擇一個角色',
        RequiredFieldInvalid: '必填字段無效,請檢查',

        PasswordTooShort: '密碼使用 8 個或更多字符',
        PasswordRequireUppercase: '密碼必須至少包含一個大寫字符',
        PasswordRequireLowercase: '密碼必須至少包含一個小寫字符',
        PasswordRequireDigit: '密碼必須至少包含一位數字',
        PasswordRequireSymbol: '密碼必須至少包含一個符號',
        ConfirmPasswordNotMatch: '密碼不匹配',
        RequireDigit: '必須是數字',
    },
    Snackbar: {
        InvalidLogin: '用戶名或密碼無效',
        SessionExpired: '您的會話已過期，請重新登錄',
        NoDateRangeRecord: '未找到記錄，請選擇其他日期範圍並重試',
    },
};

export default tc;
