import {
    AccountCircle,
    Home,
    LocationOn,
    MiscellaneousServices,
    Settings,
    SupervisorAccount,
    ChangeCircle,
    CardGiftcard,
    Microwave,
    AccountBox,
    Map,
    Public,
    ManageSearch,
    ManageAccounts,
    Article,
    FileCopy,
    AssignmentLate,
} from '@mui/icons-material';

const icons = {
    home: Home,
    settings: MiscellaneousServices,
    myaccount: AccountCircle,
    alluser: SupervisorAccount,
    global_setting: Settings,
    location: LocationOn,
    transaction: ChangeCircle,
    gift: CardGiftcard,
    machine: Microwave,
    member: AccountBox,
    map: Map,
    server: Public,
    history: ManageSearch,
    ipMan: ManageAccounts,
    logCenter: Article,
    duplicate: FileCopy,
    lateRedemption: AssignmentLate,
};

// eslint-disable-next-line
export default [
    {
        id: 'navigation',
        type: 'group',
        children: [
            // {
            //     id: 'dashboard',
            //     title: 'Dashboard',
            //     type: 'item',
            //     icon: icons['home'],
            //     url: '/dashboard',
            // },
            { id: 'dashboard', title: 'Map.Title', type: 'item', icon: icons['map'], url: '/mapview' },
            {
                id: 'transaction',
                title: 'Transaction.Title',
                type: 'item',
                icon: icons['transaction'],
                url: '/transaction',
            },
            // { id: 'server', title: 'Server.Title', type: 'item', icon: icons['server'], url: '/server' },
            // { id: 'history', title: 'History.Title', type: 'item', icon: icons['history'], url: '/machineLogHistory' },
            {
                id: 'location',
                title: 'Location.Title',
                type: 'item',
                icon: icons['location'],
                url: '/location',
            },
            {
                id: 'member',
                title: 'Member.Title',
                type: 'item',
                icon: icons['member'],
                url: '/member',
            },
            {
                id: 'machine',
                title: 'Machine.Title',
                type: 'item',
                icon: icons['machine'],
                url: '/machine',
            },
            {
                id: 'gift',
                title: 'Gift.Title',
                type: 'item',
                icon: icons['gift'],
                url: '/gift',
            },
            {
                id: 'ipManagement',
                title: 'IpManagement.Title',
                type: 'item',
                icon: icons['ipMan'],
                url: '/ipManagement'
            },
            {
                id: 'logCenter',
                title: 'LogCenter.Title',
                type: 'item',
                icon: icons['logCenter'],
                url: '/logCenter'
            },
            {
                id: 'duplicate',
                title: 'Duplicate.Title',
                type: 'item',
                icon: icons['duplicate'],
                url: '/duplicate'
            },
            {
                id: 'lateRedemption',
                title: 'LateRedemption.Title',
                type: 'item',
                icon: icons['lateRedemption'],
                url: '/lateRedemption'
            },
            {
                id: 'settings',
                title: 'Setting.Title',
                type: 'collapse',
                icon: icons['settings'],
                children: [
                    {
                        id: 'setting_myaccount',
                        title: 'Setting.My_Account.Title',
                        type: 'item',
                        icon: icons['myaccount'],
                        url: '/setting/myaccount',
                    },
                    {
                        id: 'setting_alluser',
                        title: 'Setting.User_Management.Title',
                        type: 'item',
                        icon: icons['alluser'],
                        url: '/setting/alluser',
                    },
                    {
                        id: 'setting_allcontractor',
                        title: 'Setting.Contractor_Management.Title',
                        type: 'item',
                        icon: icons['alluser'],
                        url: '/setting/allcontractor',
                    },
                    // {
                    //     id: 'setting_global',
                    //     title: 'Global Settings',
                    //     type: 'item',
                    //     icon: icons['global_setting'],
                    //     url: '/setting/global',
                    // },
                ],
            },
        ],
    },
];
