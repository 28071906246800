const en = {
    Lang: 'en',
    Edit: 'Edit',
    Create: 'Create',
    Logout: 'Logout',
    Global: {
        Home: 'Home',
        NO_RECORD: 'No record',
        Cancel: 'CANCEL',
        Clear: 'CLEAR',
        Submit: 'SUBMIT',
        Reset: 'RESET',
        Edit: 'Edit',
        Create: 'Create',
        DeleteReminder: 'Are you sure to delete record ?',
        User: 'User',
        Upload: 'UPLOAD',
    },
    Login: {
        WebPortal: 'Web Portal',
        Username: 'Username',
        Password: 'Password',
        Login: 'Login',
    },
    Muidatatable: {
        body: {
            noMatch: 'Sorry, no matching records found',
            toolTip: 'Sort',
        },
        pagination: {
            next: 'Next Page',
            previous: 'Previous Page',
            rowsPerPage: 'Rows per page:',
            displayRows: 'of',
        },
        toolbar: {
            search: 'Search',
            downloadCsv: 'Download CSV',
            print: 'Print',
            viewColumns: 'View Columns',
            filterTable: 'Filter Table',
        },
        filter: {
            all: 'All',
            title: 'FILTERS',
            reset: 'RESET',
        },
    },
    Alert: {
        Title: 'Alert!',
        Content1: 'Change your password periodically.',
        Content2: 'Do not share your password',
    },
    Dashboard: {
        Title: 'Dashboard',
    },
    Map: {
        Title: 'Map',
        MachineID: 'MachineID',
        Description: 'Description',
        Status: 'Status',
        ServerStatus: 'Server Status',
        Alerts: 'Alerts or Events',
        Index: 'Alert ID',
        CapacityStatus: 'Capacity Status',
        GeneralStatus: 'General Status',
        SpecialStatus: 'Special Status',
        SelfDefinedCode: 'Self Defined',
        Timestamp: 'Timestamp',
        createdAt: 'Created Time',
        Bin_Type: 'Bin Type',
        Level: 'Level',
        Code: 'Code'
    },
    Server: {
        Title: 'Server',
        Server: 'SERVER',
        Last_heartbeat: 'Last HeartBeat',
        Status: 'Status',
        Index: 'No.',
    },
    History: {
        Index: 'No.',
        Title: 'History',
        MachineID: 'MachineID',
        Description: 'Description',
        Last_heartbeat: 'Last HeartBeat',
        Status: 'Status',
    },
    Location: {
        Title: 'Location',
        Create: 'Create Location',
        Edit: 'Edit Location',
        Location_ID: 'Location ID',
        CP_ID: 'CPID',
        Latitude: 'CP X',
        Longitude: 'CP Y',
        District: 'District',
        Location_Type: 'Location Type',
        Address: 'Address (English)',
        Address_zh_hant: 'Address (TraditionalChinese)',
        Remarks: 'Remarks',
        LastUpdate: 'Last Update'
    },
    Transaction: {
        Title: 'Transaction',
        Transaction_ID: 'Transaction ID',
        Transaction_Time: 'Transaction Time',
        Transaction_Type: 'Transaction Type',
        Location_ID: 'Location ID',
        Machine: 'Machine ID',
        Staff: 'Staff ID',
        Member_ID: 'Member ID',
        Green_Score: 'Green Score',
        Type: 'Type',
        Weight: 'Weight',
        Source: 'Source',
        Status: 'Status',
        Import_Transaction_CSV: 'Import Transcation CSV',
        Drop_file_content_1: 'Drop the file here ...',
        Drop_file_content_2: 'Drop the file here, or click to select file.',
        Export_Transaction_CSV: 'Export Transaction CSV',
        StartDate: 'Start Date',
        EndDate: 'End Date',
    },
    Member: {
        Title: 'Member',
        Download_Member: 'Download Member CSV',
    },
    Machine: {
        Title: 'Machine',
        // Edit_gift: 'Edit Machine',
        // Create_gift: 'Create Gift',
        Machine_ID: 'Machine ID',
        Serial_Number: 'Serial Number',
        Contractor: 'Contractor',
        Machine_Type: 'Machine Type',
        Location_ID: 'Location ID',
        Bin_Weight: 'Bin Weight',
        Bin_Level: 'Bin Level',
        Remarks: 'Remarks',
        Status: 'Status',
        Start_Time: 'Start Time',
        Close_Time: 'Close Time',
        Created_at: 'Created at',
        Updated_at: 'Updated at',
        binWeightOrLevelError: 'bin weight or bin level can only be number',
    },
    Gift: {
        Title: 'Gift',
        Name_tc: 'Gift Name (TC)',
        Name_sc: 'Gift Name (SC)',
        Name_en: 'Gift Name (ENG)',
        greenScore: 'greenScore',
        Name: 'Gift Name',
        Edit_gift: 'Edit Gift',
        Create_gift: 'Create Gift',
        Gift_ID: 'Gift ID',
        Description: 'Description',
        Remarks: 'Remarks',
        Created_at: 'Created at',
        Updated_at: 'Updated at',
    },
    IpManagement: {
        Title: 'IP Management',
        IP_Address: 'IP Address',
        Machine_ID: 'Machine ID',
        Active_Time: 'Active Time',
        Activate: 'Activate',
        Active_Date_Picker: 'Active Date Picker',
        Active_Time_Picker: 'Active Time Picker',
        Not_Active: 'Not Active',
    },
    LogCenter: {
        Title: 'LOG Center',
        MemberID: 'MemberID',
        MachineID: 'MachineID',
        Type: 'Type',
        TransactionTime: 'TransactionTime',
        DuplicateData: 'Duplicate Data',
        MachineIDnLocationID: 'MachineID & LocationID',
        Whitelist: 'Whitelist',
        IP: 'IP',
        Path: 'Path',
        LocationID: 'LocationID',
        Invaild: 'Invaild',
        Weight: 'Weight',
        GreenScore: 'GreenScore',
    },
    Duplicate: {
        Title: 'Duplicate Data',
        MemberID: 'MemberID',
        MachineID: 'MachineID',
        TransactionID: 'TransactionID',
        TransactionType: 'TransactionType',
        Weight: 'Weight',
        GreenScore: 'GreenScore',
        TransactionDate: 'TransactionDate',
        TransactionTime: 'TransactionTime',
        Type: 'Type',
        AdjustmentData: 'Adjustment Data',
        LocationID: 'LocationID',
        Remarks: 'Remarks',
        Updated_at: 'Updated at',
    },
    LateRedemption: {
        Title: 'Late Redemption',
        MemberID: 'MemberID',
        MachineID: 'MachineID',
        TransactionID: 'TransactionID',
        TransactionType: 'TransactionType',
        Weight: 'Weight',
        GreenScore: 'GreenScore',
        TransactionTime: 'TransactionTime',
        Type: 'Type',
        Updated_at: 'Updated at',
        LocationID: 'LocationID',
    },
    Setting: {
        Title: 'Setting',
        My_Account: {
            Title: 'My Account',
            Account_Details: 'Account Details',
            Change_Password: 'Change Password',
            Staff_ID: 'Staff ID',
            Username: 'Username',
            Role: 'Role',
            Current_Password: 'Current Password',
            New_Password: 'New Password',
            Re_enter: 'Re-enter New Password',
            Change_Password_button: 'CHANGE PASSWORD',
            OLD_PASSWORD_NOT_MATCH: 'Current password does not match',
            NEW_PASSWORD_SAME_AS_OLD_PASSWORD: 'New password must be different',
            USERNAME_DUPLICATED: 'Username already in use',
            PasswordChanged: 'Password changed successfully!',
        },
        User_Management: {
            Title: 'User Management',
            Staff_ID: 'Staff ID',
            User: 'User',
            Role: 'Role',
            Username: 'Username',
            Created_at: 'Created Date',
            Staff: 'Staff',
            Last_Modification: 'Last Modification',
            Reset_Password_button: 'RESET PASSWORD',
            New_Password: 'New Password',
            Re_enter: 'Re-enter New Password',
            Change_Password_button: 'CHANGE PASSWORD',
        },
        Contractor_Management: {
            Title: 'Contractor Management',
            Contractor_ID: 'ContractorID',
        },
    },
    Validation: {
        Required: 'Required',
        InvalidEmailFormat: 'Must be a valid email',
        RequireEmail: 'Enter a email address',
        RequirePassword: 'Enter a password',
        RequireConfirmPassword: 'Confirm your password',
        RequireOldPassword: 'Enter current password',
        RequireNewPassword: 'Enter new password',
        RequireRole: 'Select a role',
        RequiredFieldInvalid: 'Required field are invalid, please check',

        PasswordTooShort: 'Use 8 characters or more for your password',
        PasswordRequireUppercase: 'Password must contains at least one uppercase character',
        PasswordRequireLowercase: 'Password must contains at least one lowercase character',
        PasswordRequireDigit: 'Password must contains at least one digit',
        PasswordRequireSymbol: 'Password must contains at least one symbol',
        ConfirmPasswordNotMatch: 'Password does not match',
        RequireDigit: 'Must be in digit format ',
    },
    Snackbar: {
        InvalidLogin: 'Invalid username or password',
        SessionExpired: 'Your session has expired, please login again',
        NoDateRangeRecord: 'No record found, please select a different date range and try again',
    },
};

export default en;
