import axios from 'axios';
import config from './config';

const base = config.api_base_url;

const skipRefresh = (url) => {
    const list = [base + '/menuItem', base + '/staff/login', base + '/staff/refresh_token', base + '/staff/revoke_token'];
    return list.includes(url);
};

const MenuItem = {
    get_all: async () => axios.get(base + '/menuItem'),
};

const Staff = {
    login: async (username, password) => axios.post(base + '/staff/login', { username, password }),

    refresh_token: async (refresh_token) => axios.post(base + '/staff/refresh_token', { refresh_token }),

    revoke_token: async (refresh_token) => axios.delete(base + '/staff/revoke_token', { refresh_token }),

    create_one: async (staffId, username, password, role) => axios.post(base + '/staff', { staffId, username, password, role }),

    get_all: async () => axios.get(base + '/staff'),

    get_me: async () => axios.get(base + '/staff/me'),

    get_one: async (staff_id) => axios.get(base + '/staff/' + staff_id),

    update_one: async (staffId, username, role) => axios.put(base + '/staff/' + staffId, { username, role }),

    update_one_password: async (staffId, newPassword, repeatedPassword) =>
        axios.put(base + '/staff/' + staffId + '/password', { staffId, newPassword, repeatedPassword }),

    update_me_password: async (old_password, newPassword, repeatedPassword) =>
        axios.put(base + '/staff/me/password', { old_password, newPassword, repeatedPassword }),

    delete_one: async (staff_id) => axios.delete(base + '/staff/' + staff_id),

    reset_password_request: async (staffId) => axios.post(base + '/staff/forgetPassword', { staffId }),
};

const Gift = {
    create_one: async (giftId, description, remarks, name_tc, name_sc, name_en, greenScore) =>
        axios.post(base + '/gift/', { giftId, description, remarks, name_tc, name_sc, name_en, greenScore }),

    get_all: async () => axios.get(base + '/gift/'),

    get_one: async (giftId) => axios.get(base + '/gift/' + giftId),

    update_one: async (giftId, description, remarks, name_tc, name_sc, name_en, greenScore) =>
        axios.put(base + '/gift/', { giftId, description, remarks, name_tc, name_sc, name_en, greenScore }),

    delete_one: async (giftId) => axios.delete(base + '/gift/' + giftId),

    export_csv: async () => axios.get(base + '/gift/csv', { responseType: 'blob' }),
};

const Transaction = {
    get_duplicate: async () => axios.get(base + '/transaction/duplicate'),

    get_all: async (params, cancelToken) => axios.get(base + '/transaction', { params, cancelToken }),

    export_csv: async (params) => axios.get(base + '/transaction/csv', { params, responseType: 'blob' }),

    import_csv: async (file) => {
        let formData = new FormData();
        formData.append('file', file);
        return axios.post(base + '/transaction/csv', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    },
};

const Member = {
    export_csv: async () => axios.get(base + '/member/csv', { responseType: 'blob' }),
    findMemberDuplicate: async () => axios.get(base + '/member/userDuplicate'),
    resolveDuplicate: async (body) => axios.post(base + '/member/resolveUserDuplicate', { body }),
};

const GlobalSetting = {
    get_all: async () => axios.get(base + '/globalsetting/info'),

    get_one: async (id) => axios.get(base + '/globalsetting/info/' + id),

    update_one: async (id, value) => axios.post(base + '/globalsetting/updateSetting', { id, value }),
};

const Dashboard = {
    loading: async () => await axios.get(base + '/machine/location/status'),

    get_one: async (date) => await axios.get(base + '/dashboard/' + date),

    get_dates: async () => axios.get(base + '/dashboard/dates'),
};

const Location = {
    create_one: async ({ locationID, latitude, longitude, address, address_zh_hant, locationType, remarks, cpid, district }) =>
        axios.post(base + '/location', { locationID, latitude, longitude, address, address_zh_hant, locationType, remarks, cpid, district }),

    get_all: async () => axios.get(base + '/location'),

    get_one: async (id) => axios.get(base + '/location/' + id),

    update_one: async (id, { latitude, longitude, address, address_zh_hant, locationType, remarks, cpid, district }) =>
        axios.put(base + '/location/' + id, { latitude, longitude, address, address_zh_hant, locationType, remarks, cpid, district }),

    delete_one: async (id) => axios.delete(base + '/location/' + id),
};

const Machine = {
    get_all: async () => await axios.get(base + '/machine'),

    create_one: async (body) => await axios.post(base + '/machine', body),

    get_one: async (machineID) => await axios.get(base + '/machine/' + machineID),

    edit_one: async (machineID, body) => await axios.put(base + '/machine/' + machineID, body),

    delete_one: async (machineID) => await axios.delete(base + '/machine/' + machineID),

    export_csv: async () => await axios.get(base + '/machine/csv', { responseType: 'blob' }),

    get_log: async () => await axios.get(base + '/machineSL'),

    get_top30Log: async () => await axios.get(base + '/machineSL/getTop30Log'),

    get_sl: async () => await axios.get(base + '/machineSL/getSL'),

    get_latest_capacity_status_log: async (machineID) => await axios.get(base + '/machineSL/getLatestCapacityStatusRecord/' + machineID),

    get_latest_general_status_log: async (machineID) => await axios.get(base + '/machineSL/getLatestGeneralStatusRecord/' + machineID),

    get_latest_special_status_log: async (machineID) => await axios.get(base + '/machineSL/getLatestSpecialStatusRecord/' + machineID),
};

const Contractor = {
    get_all: async () => await axios.get(base + '/contractor'),

    get_history: async () => await axios.get(base + '/contractorHBHistory'),

    get_exists: async () => await axios.get(base + '/contractor/allContractor'),

    get_one: async (contractorID) => axios.get(base + '/contractor/' + contractorID),

    create_one: async (contractorID, password) => axios.post(base + '/contractor/addContractor', { contractorID, password }),

    update_one: async (contractorID, id) => axios.put(base + '/contractor/' + id, { contractorID }),

    delete_one: async (id) => axios.delete(base + '/contractor/' + id),

    update_one_password: async (contractorID, newPassword, repeatedPassword, id) =>
        axios.put(base + '/contractor/' + id + '/password', { contractorID, newPassword, repeatedPassword }),
};

const ContractorName = {
    get_all: async () => await axios.get(base + '/contractorName'),
    create_one: async (body) => axios.post(base + '/contractorName/addContractor', body),
}



const IpRecord = {
    get_all: async () => await axios.get(base + '/ipRecord'),
    create_one: async (body) => await axios.post(base + '/ipRecord', body),
    delete_one: async (ipAddress) => await axios.delete(base + '/ipRecord/' + ipAddress),
    edit_one: async (body) => await axios.put(base + '/ipRecord/', body),
    export_csv: async () => await axios.get(base + '/ipRecord/csv', { responseType: 'blob' }),
    get_one: async (ipAddress) => await axios.get(base + '/ipRecord/' + ipAddress),
    get_global: async () => await axios.get(base + '/ipRecord/global'),
    update_global: async (value) => await axios.put(base + '/ipRecord/global', { value: value })
};

const LogCenter = {
    duplicate: async () => await axios.get(base + '/logCenter' + '/duplicate'),
    MLid: async () => await axios.get(base + '/logCenter' + '/MLid'),
    whitelist: async () => await axios.get(base + '/logCenter' + '/whitelist'),
}

const Duplicate = {
    get_all: async (params, cancelToken) => await axios.get(base + '/duplicate', { params, cancelToken }),
    cancel: async (body) => await axios.post(base + '/duplicate' + '/cancel', body),
    adjustment: async (body) => await axios.post(base + '/duplicate' + '/adjustment', body)
}

const LateRedemption = {
    get_all: async () => await axios.get(base + '/lateRedemption')
}

export default {
    skipRefresh,
    MenuItem,
    Staff,
    Gift,
    Transaction,
    Member,
    GlobalSetting,
    Dashboard,
    Location,
    Machine,
    Contractor,
    ContractorName,
    IpRecord,
    LogCenter,
    Duplicate,
    LateRedemption
};
