// eslint-disable-next-line
export default {
    basename: '/',
    // api_base_url: 'https://greenmachine.hkrnd.com/api' /* 10.0.1.180 (internal development) */,
    // api_base_url: 'https://green.aws.uat.hkrnd.com/api',
    api_base_url: 'http://localhost:3003/api',
    // api_base_url: 'https://hkpc.greenycoins.gov.hk/api' /* production */,
    // api_base_url: 'https://hkpcdev.greenycoins.gov.hk/api' /* hkpc testing */,
    home_menu_item_id: 'location',
    home_url: '/transaction',
    theme: 'light',
    rtlLayout: false,
    i18n: 'en',
};
